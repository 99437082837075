import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { parse, isAfter } from 'date-fns';
import {
  ApiCardBody,
  ApiCardHead,
  ApisCatalogueCard,
  ApisCatalogueContainer,
  ApisCatalogueHeader,
  ApisCatalogueList,
  Container,
  FeedbackContainer,
  FiltersColumn,
  FiltersHeader,
  FiltersList,
  FormLabelStyled,
} from './styles';
import Layout from '../../layout';
import { SimpleLoading } from '../../components/Loading';
import { api } from '../../utils/api';
import { CheckboxStyled } from '../../components/CheckboxNew/styles';
import { filterOptions } from '../../config/filterOptions';

interface ApiProps {
  createdAt: string;
  context: {
    code: string;
  };
}

const Panel: React.FC = () => {
  const apisCatalogue = Array(10).fill({
    id: 1,
    context: 'Cartões',
    version: '1.0.0',
    title: 'API 1',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at porta ex.',
    date: '08/03/2022',
  });

  const [pageLoading, setPageLoading] = useState(true);
  const [pageMessage, setPageMessage] = useState('');
  const [allApis, setAllApis] = useState<ApiProps[]>([]);
  const [apisList, setApisList] = useState([...apisCatalogue]);
  const [selectedBoxes, setSelectedBoxes] = useState(['all']);

  const checkArrayLength = () => {
    if (Array.isArray(apisList) && apisList.length > 0) {
      setPageMessage('');
    } else {
      setPageMessage('Nenhuma API encontrada.');
    }
  };

  const orderByDate = (a: ApiProps, b: ApiProps) => {
    const firstDate = parse(a.createdAt, 'dd/MM/yyyy HH:mm', new Date());
    const secondDate = parse(b.createdAt, 'dd/MM/yyyy HH:mm', new Date());
    const past = isAfter(firstDate, secondDate);
    const future = isAfter(secondDate, firstDate);

    if (past) {
      return 1;
    }
    if (future) {
      return -1;
    }

    return 0;
  };

  async function getApis() {
    setPageLoading(true);
    setPageMessage('');

    try {
      const response = await api.get('/filter/visible');

      if (response.data) {
        const orderedApis = [...response.data].sort(orderByDate);
        setAllApis(orderedApis as any);
        setApisList(orderedApis);
      } else {
        const error = 'Houve um problema com essa requisição.';
        toast.error(error);
        setPageMessage(error);
      }
    } catch (e) {
      const error = 'Houve um problema com essa requisição.';
      toast.error(error);
      setPageMessage(error);
    }

    setPageLoading(false);
  }

  const downloadByName = async (pathFile: string) => {
    try {
      const response = await api.get(`/aws/download/file?filePath=${pathFile}`);

      const name = pathFile.split('/')[1];
      if (response.data) {
        const link = document.createElement('a');
        link.href = response.data.base64Content;
        link.download = `${response.data.fileName}`;
        link.click();
      } else {
        const error = 'Houve um problema com essa requisição.';
        toast.error(error);
        // setPageMessage(error);
      }
    } catch (e: any) {
      const message = 'Houve um problema com essa requisição.';
      const error =
        e?.response?.data?.errors[0]?.code === '-1'
          ? 'Não existe arquivo de download.'
          : message;
      toast.error(error);
      // setPageMessage(error);
    }
  };
  async function downloadApi(id: string, name: string) {
    // setPageLoading(true);
    // setPageMessage('');

    try {
      const response = await api.get(`/aws/download/${id}`);

      if (response.data) {
        // setAllApis(response.data);
        // setApisList(response.data);

        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
          JSON.stringify(response.data),
        )}`;
        const link = document.createElement('a');
        link.href = jsonString;
        link.download = `${name}.json`;

        link.click();
      } else {
        const error = 'Houve um problema com essa requisição.';
        toast.error(error);
        // setPageMessage(error);
      }
    } catch (e: any) {
      const message = 'Houve um problema com essa requisição.';
      const error =
        e?.response?.data?.errors[0]?.code === '-1'
          ? 'Não existe arquivo de download.'
          : message;
      toast.error(error);
      // setPageMessage(error);
    }

    // setPageLoading(false);
  }

  const filterApis = () => {
    if (selectedBoxes.includes('all') || !allApis.length) {
      setApisList(allApis);
    } else {
      const filteredApis = allApis.filter((api) =>
        selectedBoxes.includes(api?.context?.code),
      );
      setApisList(filteredApis);
    }
  };

  const handleChangeFilter = (code: string, checked: boolean) => {
    if (code === 'all') {
      setSelectedBoxes(['all']);
    } else if (checked) {
      const codeRemoved = selectedBoxes.filter((item) => item !== code);

      if (codeRemoved.length) {
        setSelectedBoxes([...codeRemoved]);
      } else {
        setSelectedBoxes(['all']);
      }
    } else {
      const codeAdded = selectedBoxes.filter((item) => item !== 'all');

      codeAdded.push(code);

      setSelectedBoxes([...codeAdded]);
    }
  };

  useEffect(() => {
    filterApis();
  }, [selectedBoxes]);

  useEffect(() => {
    getApis();
  }, []);

  useEffect(() => {
    checkArrayLength();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apisList]);

  return (
    <Layout>
      <Container>
        <FiltersColumn>
          <FiltersHeader>
            <h3>Filtros</h3>
          </FiltersHeader>
          <FiltersList>
            {filterOptions.map((option) => {
              const checked = !!selectedBoxes.filter(
                (item) => item === option.code,
              ).length;

              return (
                <FormLabelStyled
                  label={option.description}
                  name={option.code}
                  control={<CheckboxStyled checked={checked} />}
                  onChange={() => handleChangeFilter(option.code, checked)}
                />
              );
            })}
          </FiltersList>
        </FiltersColumn>
        <ApisCatalogueContainer>
          {pageLoading || pageMessage ? (
            <FeedbackContainer>
              {pageLoading ? <SimpleLoading /> : <p>{pageMessage}</p>}
            </FeedbackContainer>
          ) : (
            <>
              <ApisCatalogueHeader>
                <h2>Catálogo de APIs</h2>
                <p>Lista de APIs disponíveis:</p>
              </ApisCatalogueHeader>
              <ApisCatalogueList>
                {apisList.map((api) => {
                  const {
                    id,
                    context,
                    version,
                    name,
                    description,
                    createdAt,
                    file,
                    documentation,
                  } = api;
                  const date = createdAt;

                  return (
                    <ApisCatalogueCard key={id}>
                      <ApiCardHead>
                        <div>{context?.description}</div>
                        <p>{version}</p>
                      </ApiCardHead>
                      <ApiCardBody>
                        <h3>{name}</h3>
                        <p>{description}</p>
                        <span>{date}</span>
                        {file ? (
                          <button
                            type="button"
                            onClick={() => downloadByName(file)}
                          >
                            Clique aqui para acessar o JSON.
                          </button>
                        ) : (
                          ''
                        )}
                        {documentation ? (
                          <button
                            type="button"
                            onClick={() => downloadByName(documentation)}
                          >
                            Clica aqui para acessar a Doc.
                          </button>
                        ) : (
                          ''
                        )}
                      </ApiCardBody>
                    </ApisCatalogueCard>
                  );
                })}
              </ApisCatalogueList>
            </>
          )}
        </ApisCatalogueContainer>
      </Container>
    </Layout>
  );
};

export default Panel;
