/* eslint-disable @typescript-eslint/no-use-before-define */
import axios from 'axios';
import { Buffer } from 'buffer';
import { JSEncrypt } from 'jsencrypt';

interface TokenProps {
  RefreshToken?: any;
  ExpiresAt?: number;
  IdToken?: string;
  TokenType?: string;
}

async function getOafHeaders() {
  return {
    'x-app-token': await getToken(),
    'x-api-key': process.env.REACT_APP_OAF_APPKEY || '',
  };
}

async function getToken() {
  let token = getTokenFromSession();

  if (token?.IdToken === undefined) {
    token = await createNewToken();
    saveTokenInSession(token);
  } else if (
    token?.ExpiresAt &&
    Number(new Date().getTime()) > Number(new Date(token.ExpiresAt))
  ) {
    token = await refreshToken(token);
    saveTokenInSession(token);
  }
  // else {
  //   // console.log('## Using the same token...');
  // }

  const result = `${token?.TokenType} ${token?.IdToken}`;
  // console.log( `@@ getToken() = ${ result }` )

  return result;
}

async function createNewToken() {
  // console.log('## creating new token...');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_OAF_APPKEY || '',
    },
  };

  const payload = {
    value: encryptUserPwd({
      USERNAME: process.env.REACT_APP_OAF_USERNAME || '',
      PASSWORD: process.env.REACT_APP_OAF_PASSWORD || '',
    }),
  };

  // console.log( `@@ payload = ${ JSON.stringify( payload ) }` )

  const oafAuthApiUrl =
    process.env.REACT_APP_OAF_AUTHAPIURL || 'http://localhost';
  const oafAppId = process.env.REACT_APP_OAF_APPLICATIONID || 1;

  const result = await axios
    .post(`${oafAuthApiUrl}/${oafAppId}/get-token`, payload, config)
    .then((response) => {
      return response.data;
    });

  const newToken = {
    ...result,
    ExpiresAt: calculateExpiresAt(result.ExpiresIn),
  };

  // console.log( `@@ createNewToken() = ${ JSON.stringify( newToken ) }` )

  return newToken;
}

async function refreshToken(token: TokenProps) {
  // console.log('## refreshing new token...');

  const headers = {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_OAF_APPKEY || '',
    },
  };

  const payload = {
    REFRESH_TOKEN: token.RefreshToken,
  };

  const oafAuthApiUrl =
    process.env.REACT_APP_OAF_AUTHAPIURL || 'http://localhost';
  const oafAppId = process.env.REACT_APP_OAF_APPLICATIONID || 1;

  // console.log( `@@ payload = ${ JSON.stringify( payload ) }` )

  const result = await axios
    .post(`${oafAuthApiUrl}/${oafAppId}/refresh-token`, payload, headers)
    .then((response) => {
      return response.data;
    });

  const newToken = {
    ...result,
    RefreshToken: token.RefreshToken,
    ExpiresAt: calculateExpiresAt(result.ExpiresIn),
  };

  // console.log( `@@ refreshToken() = ${ JSON.stringify( newToken ) }` )

  return newToken;
}

function calculateExpiresAt(expiresIn: number) {
  const expiresAt = new Date();
  expiresAt.setSeconds(expiresAt.getSeconds() + (expiresIn - 60));
  return expiresAt.getTime();
}

function encryptUserPwd(login: any) {
  const publicKey = process.env.REACT_APP_OAF_PUBLICKEY || '';

  if (publicKey) {
    const pubkeyAsUtf8 = Buffer.from(publicKey, 'base64').toString('utf8');

    // console.log(`@@ pubkeyAsUtf8 = ${pubkeyAsUtf8}`);

    // return pubkeyAsUtf8;
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(pubkeyAsUtf8);

    const encrypted = encrypt.encrypt(JSON.stringify(login));
    // console.log( `@@ encryptUserPwd() = ${ encrypted }` )

    return encrypted;
  }
  return '';
}

function getTokenFromSession() {
  const saved = sessionStorage.getItem('token'); // ! Ponto de atenção
  const parsed = saved && JSON.parse(saved);

  // console.log('env');
  // console.log(
  //   `${process.env.REACT_APP_OAF_AUTHAPIURL}/${process.env.REACT_APP_OAF_APPLICATIONID}/get-token`,
  // );

  return parsed;
}

function saveTokenInSession(value: TokenProps) {
  sessionStorage.setItem('token', JSON.stringify(value));
}

export default getOafHeaders;
