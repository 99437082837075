import { FormControlLabel } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 3rem;
  border-bottom: 1px solid var(--gray-200);
`;

export const FeedbackContainer = styled.div`
  display: flex;
  height: 40rem;
  align-items: center;
  justify-content: center;
`;

export const FiltersColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
  padding: 8rem 2rem 2rem 8rem;
`;

export const FiltersHeader = styled.div`
  margin-bottom: 1.6rem;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid var(--gray-200);

  h3 {
    color: var(--gray-800);
    font-weight: 700;
    font-size: 2rem;
  }
`;

export const FiltersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0rem;
`;

export const FormLabelStyled = styled(FormControlLabel)`
  .MuiTypography-root {
    color: var(--gray-500);
    font-family: Montserrat;
    font-size: 1.4rem;
  }
`;

export const ApisCatalogueContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--gray-100);
  padding: 8rem 7.6rem;
`;

export const ApisCatalogueHeader = styled.div`
  margin-bottom: 4rem;

  h2 {
    font-size: 3.2rem;
    color: var(--gray-800);
    font-weight: 700;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.8rem;
    color: var(--gray-800);
    font-weight: 400;
  }
`;

export const ApisCatalogueList = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(32.2rem, 1rem));
  grid-gap: 3.2rem;
`;

export const ApisCatalogueCard = styled.button`
  background-color: var(--white);
  width: 100%;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  box-shadow: var(--boxShadow);
  padding: 1.6rem;
`;

export const ApiCardHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;
  width: 100%;

  > div {
    background-color: var(--main-200);
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.6rem 0.8rem;
    color: var(--main-800);
    font-weight: 600;
    font-size: 1.2rem;
  }

  > p {
    color: var(--main-300);
    font-weight: 600;
    font-size: 1.4rem;
  }
`;

export const ApiCardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;

  > h3 {
    color: var(--greenLight);
    font-size: 2rem;
    font-weight: 600;
  }

  > button {
    background: white;
    color: var(--greenLight);
  }
  > p {
    color: var(--gray-500);
    font-size: 1.4rem;
    font-weight: 400;
    text-align: left;
  }

  span {
    color: var(--gray-300);
    font-size: 1.4rem;
    font-weight: 400;
  }

  a {
    color: var(--greenLight);
    font-size: 1.4rem;
    font-weight: 400;
    cursor: pointer;
  }
`;
