import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  height: 31.2rem;
  padding: 6rem 2rem;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    height: auto;
    padding: 0rem 2rem 2rem 2rem;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 96.4rem;
  gap: 5rem;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
  }

  /* @media (max-width: 361px) {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
    gap: 3rem;
  } */
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 361px) {
    align-items: flex-start;
  }

  h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 24px;
    color: var(--gray-800);
    margin-bottom: 2rem;

    @media (max-width: 361px) {
      font-size: 2rem;
      line-height: 2.9rem;
    }
  }

  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: var(--gray-800);
    margin-bottom: 2rem;
    max-width: 30rem;

    @media (max-width: 361px) {
      font-size: 1.4rem;
      padding-bottom: 1.6rem;
      text-align: left;
    }
  }

  a {
    color: var(--greenLight);
    font-size: 1.6rem;
    font-weight: 600;
    text-decoration: none;
  }
`;

export const Image = styled.div`
  display: flex;

  svg {
    color: var(--violetMidway);
    font-size: 2rem;
  }

  img {
    width: 42rem;
    border-radius: 2rem;

    @media (max-width: 450px) {
      width: 100%;
      height: 100%;
    }
  }
`;
