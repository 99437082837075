import React, { useContext } from 'react';
import * as formik from 'formik';
import * as yup from 'yup';
import { MdOutlineMarkEmailRead, MdOutlineErrorOutline } from 'react-icons/md';
import {
  Container,
  ForgotPasswordBox,
  PasswordForm,
  SuccesEmailMessage,
  FailEmailMessage,
  FormInput,
  SendCodeGradientContent,
} from './styles';
import { BasicInput } from '../Inputs/styles';
import { PrimaryButtonNew } from '../ButtonsNew/styles';
import { AccountContext } from '../Account';
import Loading from '../Loading';

interface FormValues {
  email: string;
}

const SendCodeNew: React.FC = () => {
  const {
    sendCodeToResetAccountPassword,
    sendCodeMessage,
    sendCodeWorking,
    sendCodeSuccess,
    setShowResetPassword,
  } = useContext(AccountContext);

  const { Formik } = formik;
  const schema = yup.object({
    email: yup
      .string()
      .required('o campo e-mail é obrigatório')
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Insira um endereço de e-mail válido',
      ),
  });

  const handleSendCode = ({ email }: FormValues) => {
    sendCodeToResetAccountPassword(email);
  };

  return (
    <Container>
      <SendCodeGradientContent>
        <ForgotPasswordBox>
          <h2>Esqueci minha senha</h2>
          <p>Digite o e-mail cadastrado para receber o código:</p>
          <Formik
            onSubmit={handleSendCode}
            initialValues={{ email: '' }}
            validationSchema={schema}
            validateOnChange={false}
          >
            {({ handleSubmit, handleChange, errors, values }) => {
              const emailError = errors?.email;
              const validEmail = values?.email;

              return (
                <PasswordForm onSubmit={handleSubmit}>
                  <FormInput>
                    <BasicInput
                      placeholder="E-mail"
                      name="email"
                      onChange={handleChange}
                    />
                    <p>{emailError}</p>
                  </FormInput>
                  {sendCodeMessage &&
                    (sendCodeSuccess ? (
                      <SuccesEmailMessage>
                        <MdOutlineMarkEmailRead />
                        <p>{sendCodeMessage}</p>
                      </SuccesEmailMessage>
                    ) : (
                      <FailEmailMessage>
                        <MdOutlineErrorOutline />
                        <p>{sendCodeMessage}</p>
                      </FailEmailMessage>
                    ))}
                  {sendCodeSuccess ? (
                    <PrimaryButtonNew
                      onClick={() => setShowResetPassword(true)}
                    >
                      Continuar
                    </PrimaryButtonNew>
                  ) : (
                    <PrimaryButtonNew
                      type="submit"
                      disabled={!validEmail || sendCodeWorking}
                    >
                      Enviar código
                      {sendCodeWorking && <Loading />}
                    </PrimaryButtonNew>
                  )}
                </PasswordForm>
              );
            }}
          </Formik>
        </ForgotPasswordBox>
      </SendCodeGradientContent>
    </Container>
  );
};

export default SendCodeNew;
