import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  padding: 4rem 2rem 4rem 2rem;
  margin-bottom: 4rem;
  background-color: var(--main-100);
  @media (max-width: 520px) {
    padding: 4rem 2rem 2rem 2rem;
  }
  @media (max-width: 360px) {
    padding: 2rem 2rem 2rem 2rem;
  }

  h2 {
    margin-bottom: 3.2rem;
    font-weight: 700;
    font-size: 2rem;
    text-align: left;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 96.4rem;
  flex-direction: column;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0 0 3rem 0;
  color: var(--gray-800);

  @media (max-width: 700px) {
    margin: 0 0 4rem 0;
  }
  @media (max-width: 400px) {
    font-size: 1.8rem;
  }
`;

export const Cards = styled.div``;

export const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.6rem;
  width: 100%;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
`;
