import styled from 'styled-components';
import { DevPortalBox, GradientContent } from '../Containers/styles';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const ResetPasswordGradientContent = styled(GradientContent)``;

export const ResetPasswordBox = styled(DevPortalBox)``;

export const PasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  p {
    margin-bottom: 0rem;
  }

  > button {
    align-self: flex-end;
    width: 30rem;
  }
`;

export const FormInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 10rem;
`;

export const PasswordRequirements = styled.p`
  color: var(--gray-500);
  line-height: 1.8rem;
`;
