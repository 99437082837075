import React from 'react';

import { Container, Body } from './styles';
import Footer from '../components/Footer';

const Layout: React.FC = ({ children }) => {
  return (
    <Container>
      <Body>{children}</Body>
      <Footer />
    </Container>
  );
};

export default Layout;
