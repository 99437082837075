export const filterOptions = [
  {
    code: 'all',
    description: 'Todos',
  },
  {
    code: 'OPEN_FINANCE',
    description: 'Open Finance',
  },
  {
    code: 'CARTOES',
    description: 'Cartões',
  },
  {
    code: 'EMPRESTIMOS',
    description: 'Empréstimos',
  },
  {
    code: 'MARKET_PLACE_FINANCEIRO',
    description: 'Market Place Financeiro',
  },
  {
    code: 'ONBOARDING',
    description: 'Onboarding',
  },
  {
    code: 'PIX',
    description: 'Pix',
  },
  {
    code: 'MEIOS_DE_PAGAMENTO',
    description: 'Meios de Pagamento',
  },
  {
    code: 'SEGUROS',
    description: 'Seguros',
  },
  {
    code: 'PREVENCAO_A_FRAUDE',
    description: 'Prevenção à Fraude',
  },
];
