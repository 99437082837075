import { createGlobalStyle } from 'styled-components';
import { darken, lighten } from 'polished';
import 'react-toastify/dist/ReactToastify.css';

interface GlobalProps {
  rate: number;
}

export default createGlobalStyle<GlobalProps>`
* {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  box-sizing: border-box;
  outline: none;
}

:root {
    --tifanny: #22C9C3;
    --lightTifanny: ${lighten(0.5, '#22C9C3')};
    --petroleum: #00595B;
    --darkPetroleum: ${darken(0.05, '#00595B')};
    --lightPetroleum:#e2feff;
    --highlights:#00b7a3;
    --white: #ffffff;
    --gray: #A3A3A3;
    --lightGray: #F3F3F3;
    --darkGray: #707070;
    --blackMidway: #252525;
    --errorRed: #BC4328;
    --gray-100: #F7F7F7;
    --gray-200: #CACECD;
    --gray-300: #959D9D;
    --gray-500: #616B6A;
    --gray-800: #1F2B2A;

    --main-100: #E6FFFE;
    --main-200: #9EFAF6;
    --main-300: #22C9C3;
    --main-800: #004D49;

    --maxContent: 96.4rem;
    --borderGray: #606060;
    --footerGray: #D3D3D3;
    --newTifanny: #50EBE6;
    --darkTifanny: #65D3D7;
    --lightNewTifanny: rgba(80, 235, 230, 0.1);
    --greenLight: #00726D;
    --newLightGray: #fafafa;
    --greenish: #5AF57C;
    --lightGreenish: #E6FEEB;

    --violetMidway: #8097E1;
    --lightViolet: rgba(128, 151, 225, 0.05);

    --blackButtonColor: #46C7C7;
    --blackButtonBackground: black;
    --inputBackground: #f2f2f2;
    --inputColor: #BCBCBC;

    --success: #00b7a3;
    --error: #DC3545;

    --boxShadow: 0px 8px 12px -4px rgba(31, 43, 42, 0.16);

    --greenGradient: linear-gradient(
    90deg,
    rgba(24, 207, 214, 1) 0%,
    rgba(119, 235, 169, 1) 74%,
    rgba(179, 245, 90, 1) 100%


  );
}

body {
	line-height: 1;
    -webkit-font-smoothing: antialiased;
}
html {
  font-size: ${(props) => props.rate}%;
}
body, input, button {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}
button {
    cursor: pointer;
    outline: none;
    border: none;
    transition: all 0.2s;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a {
  text-decoration: none;
}
`;
