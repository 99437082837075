import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 7rem;
  background-color: var(--white);
  box-shadow: 0px 8px 12px -4px rgba(31, 43, 42, 0.16);
  position: relative;
  width: 100%;

  @media (max-width: 768px) {
    height: auto;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 96.4rem;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding-top: 1.6rem;
  }
`;

export const Logo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    padding-bottom: 2rem;
  }

  @media (max-width: 360px) {
    padding: 2rem;
  }

  .riachu {
    height: 1.3rem;
  }

  .midway {
    height: 3.6rem;
    margin-right: 2rem;
  }

  @media (max-width: 360px) {
    .riachu {
      height: 0.95rem;
    }

    .midway {
      height: 2.7rem;
      margin-right: 2rem;
    }
  }
`;

export const Buttons = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    text-decoration: none;
  }

  a:first-child {
    margin-right: 1rem;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;
