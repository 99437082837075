import React, { useContext } from 'react';
import { AccountContext } from '../../components/Account';
import ResetPasswordNew from '../../components/ResetPasswordNew';
import SendCodeNew from '../../components/SendCodeNew';
import Layout from '../../layout';
// import ResetPassword from '../../components/SendCode';

const ForgotPassword: React.FC = () => {
  const { showResetPassword } = useContext(AccountContext);

  return (
    <Layout>
      {showResetPassword ? <ResetPasswordNew /> : <SendCodeNew />}
    </Layout>
  );
};

export default ForgotPassword;
