import React from 'react';

import Banner from '../../components/Banner';
import AboutPortal from '../../components/AboutPortal';
import ApiFeed from '../../components/ApiFeed';

import Layout from '../../layout';
import DiscoverOpenBanking from '../../components/DiscoverOpenBanking';

const Home: React.FC = () => {
  return (
    <Layout>
      <Banner />
      <AboutPortal />
      <ApiFeed />
      <DiscoverOpenBanking />
    </Layout>
  );
};

export default Home;
