import React from 'react';
import { Container, Content, Text, Image } from './styles';
import welcomeOpenBanking from '../../assets/welcomeOpenBanking.png';

const DiscoverOpenBanking: React.FC = () => {
  return (
    <Container>
      <Content>
        <Text>
          <h2>Open Finance Midway</h2>
          <p>Descubra mais sobre a participação da Midway no Open Finance.</p>
          <a
            href="https://openfinance.midway.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            Acesse aqui nosso Portal {'>'}
          </a>
        </Text>
        <Image>
          <img src={welcomeOpenBanking} alt="Portal Open Finance" />
        </Image>
      </Content>
    </Container>
  );
};

export default DiscoverOpenBanking;
