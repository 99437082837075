import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem 0 2rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8rem 0 0 0;
  @media (max-width: 361px) {
    padding: 3.5rem 0 0 0;
  }

  h1 {
    font-size: 2.6rem;
    font-weight: 600;
    color: var(--petroleum);
    max-width: 43.4rem;
    text-align: center;
    @media (max-width: 361px) {
      font-size: 2rem;
    }
  }

  img {
    height: 28.8rem;
    margin: 5rem 0;
    @media (max-width: 361px) {
      width: 100%;
      height: 100%;
      margin-bottom: 3rem;
    }
  }

  p {
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 400;
    color: var(--borderGray);
    text-align: center;
    max-width: 70rem;
    margin-bottom: 3.5rem;
    @media (max-width: 361px) {
      font-size: 1.4rem;
    }
  }

  a {
    color: var(--greenLight);
    font-size: 2rem;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    @media (max-width: 361px) {
      font-size: 1.8rem;
    }

    img {
      max-height: 4.2rem;
      cursor: pointer;
      margin: 4rem 0;
    }
  }
`;
