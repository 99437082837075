import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';

export const CheckboxStyled = styled(Checkbox)`
  svg {
    height: 3rem;
    width: 3rem;
  }

  &.MuiCheckbox-root {
    color: var(--greenLight) !important;
  }

  &.Mui-checked {
    color: var(--greenLight) !important;
  }
`;
