import React, { useState, useEffect } from 'react';
import { parse, isAfter } from 'date-fns';
import { Link } from 'react-router-dom';
import { Container, Content, Title, Cards, Card } from './styles';
import { api } from '../../utils/api';
import Loading from '../Loading';
import Message from '../Message';
import { IApiItem } from '../../interfaces';
import {
  ApiCardBody,
  ApiCardHead,
  ApisCatalogueCard,
} from '../../pages/Panel/styles';

interface ApiProps {
  createdAt: string;
  context: {
    code: string;
  };
}

const ApiFeed: React.FC = () => {
  const [apiList, setApiList] = useState<IApiItem[]>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const orderByDate = (a: ApiProps, b: ApiProps) => {
    const firstDate = parse(a.createdAt, 'dd/MM/yyyy HH:mm', new Date());
    const secondDate = parse(b.createdAt, 'dd/MM/yyyy HH:mm', new Date());
    const past = isAfter(firstDate, secondDate);
    const future = isAfter(secondDate, firstDate);

    if (past) {
      return 1;
    }
    if (future) {
      return -1;
    }

    return 0;
  };

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const response = await api.get('/filter/visible');
      if (response?.data) {
        const orderedApis = [...response.data].sort(orderByDate);
        setApiList(orderedApis);
      }
    } catch (err) {
      setHasError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      <Content>
        <Title>
          Acesse nossa plataforma e consulte as API’s disponíveis para você
        </Title>
        <Cards>
          {hasError && apiList?.length !== 0 && (
            <Message text="Ops! Alguns itens não estão carregando." />
          )}
          {!hasError && apiList?.length === 0 && (
            <Message text="Ainda não há nenhuma API cadastrada." />
          )}
          {isLoading && Object.keys(apiList?.length !== 0) && !hasError ? (
            <Loading />
          ) : (
            <Card>
              {apiList &&
                apiList?.slice(0, 3).map((api) => {
                  const { id, context, version, name, description } = api;

                  return (
                    <ApisCatalogueCard key={id}>
                      <ApiCardHead>
                        <div>{context?.description}</div>
                        <p>{version}</p>
                      </ApiCardHead>
                      <ApiCardBody>
                        <h3>{name}</h3>
                        <p>{description}</p>
                        <Link to="/painel">{`Clique para acessar >`}</Link>
                      </ApiCardBody>
                    </ApisCatalogueCard>
                  );
                })}
            </Card>
          )}
        </Cards>
      </Content>
    </Container>
  );
};

export default ApiFeed;
