import React, { useContext, useState } from 'react';
import * as formik from 'formik';
import * as yup from 'yup';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { PrimaryButtonNew } from '../ButtonsNew/styles';
import { BasicInput, PasswordInput, RevealPasswordBox } from '../Inputs/styles';
import {
  Container,
  PasswordForm,
  PasswordRequirements,
  ResetPasswordBox,
  ResetPasswordGradientContent,
} from './styles';
import Loading from '../Loading';
import { AccountContext } from '../Account';

interface FormValues {
  code: string;
  password: string;
}

const ResetPasswordNew: React.FC = () => {
  const { resetAccountPassword, currentEmail, resetPasswordWorking } =
    useContext(AccountContext);
  const [revealPassword, setRevealPassword] = useState(false);
  const [revealConfirmPassword, setRevealConfirmPassword] = useState(false);
  const history = useHistory();

  const { Formik } = formik;
  const schema = yup.object({
    code: yup.string().required('código é obrigatório'),
    password: yup
      .string()
      .required('senha é obrigatória')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'A senha não atende aos critérios obrigatórios',
      ),
    confirmPassword: yup
      .string()
      .required('nova senha é obrigatória')
      .oneOf([yup.ref('password'), null], 'As senhas devem coincidir'),
  });

  const handleResetPassword = ({ code, password }: FormValues) => {
    resetAccountPassword(currentEmail, code, password, history);
  };

  return (
    <Container>
      <ResetPasswordGradientContent>
        <ResetPasswordBox>
          <h2>Cadastrar nova senha</h2>
          <p>Cadastre uma nova senha para acessar o portal:</p>
          <Formik
            onSubmit={handleResetPassword}
            initialValues={{ code: '', password: '', confirmPassword: '' }}
            validationSchema={schema}
            validateOnChange={false}
          >
            {({ handleSubmit, handleChange, errors, values }) => {
              const hasCode = values?.code;
              const hasPassword = values?.password;
              const passwordError = errors?.password;
              const hasConfirmPassword = values?.confirmPassword;
              const confirmPasswordError = errors?.confirmPassword;
              const canSubmit = hasCode && hasPassword && hasConfirmPassword;

              return (
                <PasswordForm onSubmit={handleSubmit}>
                  <BasicInput
                    placeholder="Código"
                    name="code"
                    onChange={handleChange}
                  />

                  <PasswordInput>
                    <BasicInput
                      placeholder="Senha"
                      name="password"
                      onChange={handleChange}
                      type={revealPassword ? 'text' : 'password'}
                      error={!!passwordError}
                    />
                    <RevealPasswordBox
                      type="button"
                      onClick={() => setRevealPassword(!revealPassword)}
                    >
                      {revealPassword ? (
                        <MdOutlineVisibilityOff />
                      ) : (
                        <MdOutlineVisibility />
                      )}
                    </RevealPasswordBox>
                  </PasswordInput>

                  <PasswordInput>
                    <BasicInput
                      placeholder="Confirmar senha"
                      name="confirmPassword"
                      onChange={handleChange}
                      type={revealConfirmPassword ? 'text' : 'password'}
                      error={!!confirmPasswordError}
                    />
                    <RevealPasswordBox
                      type="button"
                      onClick={() =>
                        setRevealConfirmPassword(!revealConfirmPassword)
                      }
                    >
                      {revealPassword ? (
                        <MdOutlineVisibilityOff />
                      ) : (
                        <MdOutlineVisibility />
                      )}
                    </RevealPasswordBox>
                  </PasswordInput>

                  <PasswordRequirements>
                    Sua senha precisa ter: No mínimo 8 caracteres, ao menos 1
                    caractere maiúsculo; ao menos 1 caractere minúsculo; ao
                    menos 1 número; ao menos 1 caractere especial.
                  </PasswordRequirements>

                  <p style={{ color: 'red' }}>
                    {passwordError || confirmPasswordError}
                  </p>

                  <PrimaryButtonNew
                    type="submit"
                    disabled={!canSubmit || resetPasswordWorking}
                  >
                    Redefinir senha
                    {resetPasswordWorking && <Loading />}
                  </PrimaryButtonNew>
                </PasswordForm>
              );
            }}
          </Formik>
        </ResetPasswordBox>
      </ResetPasswordGradientContent>
    </Container>
  );
};

export default ResetPasswordNew;
