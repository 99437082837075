import styled from 'styled-components';

export const GradientContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 8rem 2rem;
  background: var(--greenGradient);
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
`;

export const DevPortalBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 96rem;
  width: 100%;
  min-height: 63.3rem;
  /* height: auto; */
  background-color: var(--white);
  border-radius: 2rem;
  padding: 6rem;

  h2 {
    font-size: 3rem;
    font-weight: bold;
    color: var(--gray-800);
    margin-bottom: 2.4rem;
  }

  p {
    font-size: 1.6rem;
    color: var(--gray-800);
    margin-bottom: 2.4rem;
  }
`;
