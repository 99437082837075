import React from 'react';
import { Container, Content, Text, Logo, Image } from './styles';
import forDevelopers from '../../assets/forDevelopers.png';

const Banner: React.FC = () => {
  return (
    <Container>
      <Content>
        <Image>
          <img src={forDevelopers} alt="Welcome Open Finance" />
        </Image>
        <Text>
          <Logo>
            <h2>OLÁ, ESTE É O PORTAL DO DESENVOLVEDOR MIDWAY!</h2>
          </Logo>
          <p>Vamos juntos desenvolver o futuro das instituições financeiras!</p>
        </Text>
      </Content>
    </Container>
  );
};

export default Banner;
