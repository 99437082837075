import styled from 'styled-components';

export const Enabled = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 1.1rem 1rem 1.2rem;
  margin: 2.5rem 0;
  color: red;
  height: 4.6rem;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.7rem;

  border-radius: 2.5rem;
  background-color: var(--petroleum);
  color: var(--white);
`;

export const Disabled = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 1.1rem 1rem 1.2rem;
  margin: 2.5rem 0;

  height: 4.6rem;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.7rem;

  border-radius: 2.5rem;
  background-color: var(--inputBackground);
  color: var(--inputColor);
`;

export const Ghost = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 1.1rem 1rem 1.2rem;
  margin: 2.5rem 0;
  color: red;

  width: 13rem;
  height: 4.6rem;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.7rem;

  border-radius: 2.5rem;
  background-color: transparent;
  color: var(--petroleum);
  border: 1px solid var(--petroleum);

  :hover {
    background-color: var(--petroleum);
    color: var(--white);
  }
`;

export const Black = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 1.1rem 1rem 1.2rem;
  margin: 2.5rem 0;
  color: red;

  height: 4.6rem;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.7rem;

  border-radius: 2.5rem;
  background-color: var(--blackButtonBackground);
  color: var(--blackButtonColor);
`;

export const Primary = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  height: 4.6rem;

  font-family: Montserrat;
  font-weight: normal;
  font-size: 1.4rem;

  border-radius: 2.5rem;
  color: var(--white);
  background-color: var(--greenLight);

  :hover {
    background-color: var(--petroleum);
  }
`;
