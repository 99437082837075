import React, { useContext, useState } from 'react';
import * as formik from 'formik';
import * as yup from 'yup';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import { PrimaryButtonNew } from '../ButtonsNew/styles';
import {
  BasicInput,
  MaskedInputStyled,
  PasswordInput,
  RevealPasswordBox,
} from '../Inputs/styles';
import {
  Container,
  PasswordForm,
  PasswordRequirements,
  ResetPasswordBox,
  ResetPasswordGradientContent,
  SignUpOptions,
  TermAndCondition,
  UserHasAccount,
} from './styles';
import Loading from '../Loading';
import { AccountContext } from '../Account';
import { CheckboxStyled } from '../CheckboxNew/styles';

interface FormValues {
  name: string;
  email: string;
  phone: string;
  company: string;
  terms: boolean;
  password: string;
}

const SignUpNew: React.FC = () => {
  const { registerAccount, registerWorking } = useContext(AccountContext);
  const [revealPassword, setRevealPassword] = useState(false);
  const [revealConfirmPassword, setRevealConfirmPassword] = useState(false);
  const history = useHistory();

  const { Formik } = formik;
  const schema = yup.object({
    name: yup.string().required('o campo nome é obrigatório'),
    email: yup
      .string()
      .required('o campo e-mail é obrigatório')
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Insira um endereço de e-mail válido',
      ),
    phone: yup
      .string()
      .required('o campo telefone é obrigatório')
      .min(14, 'Insira um número de telefone válido')
      .max(15, 'Insira um número de telefone válido'),
    company: yup.string().required('o campo empresa é obrigatório'),
    terms: yup
      .boolean()
      .oneOf([true], 'Você deve aceitar os termos e condições para prosseguir'),
    password: yup
      .string()
      .required('senha é obrigatória')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'A senha não atende aos critérios obrigatórios',
      ),
    confirmPassword: yup
      .string()
      .required('nova senha é obrigatória')
      .oneOf([yup.ref('password'), null], 'As senhas devem coincidir'),
  });

  const handleResetPassword = ({
    name,
    company,
    terms,
    email,
    password,
  }: FormValues) => {
    const stringTerms = terms ? '1' : '0';
    registerAccount(name, company, stringTerms, email, password, history);
  };

  return (
    <Container>
      <ResetPasswordGradientContent>
        <ResetPasswordBox>
          <h2>Cadastro</h2>
          <p>
            Preencha os dados abaixo para ter acesso ao Portal do Desenvolvedor
            Midway:
          </p>
          <Formik
            onSubmit={handleResetPassword}
            initialValues={{
              name: '',
              email: '',
              phone: '',
              company: '',
              terms: false,
              password: '',
              confirmPassword: '',
            }}
            validationSchema={schema}
            validateOnChange={false}
          >
            {({ handleSubmit, handleChange, errors, values }) => {
              // const nameError = errors?.name;
              const hasName = values?.name;
              const emailError = errors?.email;
              const hasEmail = values?.email;
              const phoneError = errors?.phone;
              const hasPhone = values?.phone;
              // const companyError = errors?.company;
              const hasCompany = values?.company;
              const passwordError = errors?.password;
              const hasPassword = values?.password;
              const confirmPasswordError = errors?.confirmPassword;
              const hasConfirmPassword = values?.confirmPassword;
              const hasTerms = values?.terms;
              const canSubmit =
                hasName &&
                hasEmail &&
                hasPhone &&
                hasCompany &&
                hasPassword &&
                hasConfirmPassword &&
                hasTerms;

              // console.log(errors);
              // console.log(values);

              return (
                <PasswordForm onSubmit={handleSubmit}>
                  <BasicInput
                    placeholder="Nome completo"
                    name="name"
                    onChange={handleChange}
                  />
                  <BasicInput
                    placeholder="E-mail"
                    name="email"
                    onChange={handleChange}
                    error={!!emailError}
                  />
                  {/* <BasicInput
                    placeholder="Telefone"
                    name="phone"
                    value={hasPhone}
                    onChange={(e) =>
                      e.target.value.match(/^[0-9]+$/) != null &&
                      handleChange(e)
                    }
                    maxLength={11}
                    error={!!phoneError}
                  /> */}
                  <MaskedInputStyled
                    mask={
                      hasPhone.length > 14
                        ? '(99) 99999-9999'
                        : '(99) 9999-99999'
                    }
                    maskChar={null}
                    placeholder="Telefone"
                    name="phone"
                    onChange={handleChange}
                    error={!!phoneError}
                  />
                  <BasicInput
                    placeholder="Empresa que trabalha"
                    name="company"
                    onChange={handleChange}
                  />

                  <PasswordInput>
                    <BasicInput
                      placeholder="Senha"
                      name="password"
                      onChange={handleChange}
                      type={revealPassword ? 'text' : 'password'}
                      error={!!passwordError}
                    />
                    <RevealPasswordBox
                      type="button"
                      onClick={() => setRevealPassword(!revealPassword)}
                    >
                      {revealPassword ? (
                        <MdOutlineVisibilityOff />
                      ) : (
                        <MdOutlineVisibility />
                      )}
                    </RevealPasswordBox>
                  </PasswordInput>

                  <PasswordInput>
                    <BasicInput
                      placeholder="Confirmar senha"
                      name="confirmPassword"
                      onChange={handleChange}
                      type={revealConfirmPassword ? 'text' : 'password'}
                      error={!!confirmPasswordError}
                    />
                    <RevealPasswordBox
                      type="button"
                      onClick={() =>
                        setRevealConfirmPassword(!revealConfirmPassword)
                      }
                    >
                      {revealPassword ? (
                        <MdOutlineVisibilityOff />
                      ) : (
                        <MdOutlineVisibility />
                      )}
                    </RevealPasswordBox>
                  </PasswordInput>

                  <SignUpOptions>
                    <TermAndCondition>
                      <FormControlLabel
                        label=""
                        name="terms"
                        control={<CheckboxStyled />}
                        onChange={handleChange}
                      />

                      <p>
                        Aceito os &nbsp;
                        <a
                          href="/termos_e_condicoes_portal_desenvolvedores.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Termos e Condições
                        </a>
                      </p>
                    </TermAndCondition>
                    <UserHasAccount>
                      <p>Já possui uma conta?&nbsp;</p>
                      <Link to="/login">Entrar</Link>
                    </UserHasAccount>
                  </SignUpOptions>

                  <PasswordRequirements>
                    Sua senha precisa ter: No mínimo 8 caracteres, ao menos 1
                    caractere maiúsculo; ao menos 1 caractere minúsculo; ao
                    menos 1 número; ao menos 1 caractere especial.
                  </PasswordRequirements>

                  <p style={{ color: 'red' }}>
                    {emailError ||
                      phoneError ||
                      passwordError ||
                      confirmPasswordError}
                  </p>

                  <PrimaryButtonNew
                    type="submit"
                    disabled={!canSubmit || registerWorking}
                  >
                    Cadastrar-se
                    {registerWorking && <Loading />}
                  </PrimaryButtonNew>
                </PasswordForm>
              );
            }}
          </Formik>
        </ResetPasswordBox>
      </ResetPasswordGradientContent>
    </Container>
  );
};

export default SignUpNew;
