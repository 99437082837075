import React, { useContext, useState } from 'react';
import * as formik from 'formik';
import * as yup from 'yup';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import { PrimaryButtonNew } from '../ButtonsNew/styles';
import { BasicInput, PasswordInput, RevealPasswordBox } from '../Inputs/styles';
import {
  Container,
  ForgotPasswordLink,
  LoginActions,
  PasswordForm,
  ResetPasswordBox,
  ResetPasswordGradientContent,
  SignUpOption,
} from './styles';
import Loading from '../Loading';
import { AccountContext } from '../Account';

interface FormValues {
  email: string;
  password: string;
}

const SignInNew: React.FC = () => {
  const { loginAccount, loginWorking } = useContext(AccountContext);
  const [revealPassword, setRevealPassword] = useState(false);
  const history = useHistory();

  const { Formik } = formik;
  const schema = yup.object({
    email: yup
      .string()
      .required('o campo e-mail é obrigatório')
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Insira um endereço de e-mail válido',
      ),
    password: yup.string().required('senha é obrigatória'),
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
    //   'A senha não atende aos critérios obrigatórios',
    // ),
  });

  const handleLogin = ({ email, password }: FormValues) => {
    loginAccount(email, password, history);
  };

  return (
    <Container>
      <ResetPasswordGradientContent>
        <ResetPasswordBox>
          <h2>Login</h2>
          <p>Acessar o Portal do Desenvolvedor Midway:</p>
          <Formik
            onSubmit={handleLogin}
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={schema}
            validateOnChange={false}
          >
            {({ handleSubmit, handleChange, errors, values }) => {
              const emailError = errors?.email;
              const hasEmail = values?.email;

              const passwordError = errors?.password;
              const hasPassword = values?.password;

              const canSubmit = hasEmail && hasPassword;

              return (
                <PasswordForm onSubmit={handleSubmit}>
                  <BasicInput
                    placeholder="E-mail"
                    name="email"
                    onChange={handleChange}
                  />

                  <PasswordInput>
                    <BasicInput
                      placeholder="Senha"
                      name="password"
                      onChange={handleChange}
                      type={revealPassword ? 'text' : 'password'}
                      error={!!passwordError}
                    />
                    <RevealPasswordBox
                      type="button"
                      onClick={() => setRevealPassword(!revealPassword)}
                    >
                      {revealPassword ? (
                        <MdOutlineVisibilityOff />
                      ) : (
                        <MdOutlineVisibility />
                      )}
                    </RevealPasswordBox>
                  </PasswordInput>

                  <p style={{ color: 'red' }}>{emailError || passwordError}</p>

                  <LoginActions>
                    <ForgotPasswordLink>
                      <Link to="/esqueci-senha">Esqueci minha senha</Link>
                    </ForgotPasswordLink>
                    <PrimaryButtonNew
                      type="submit"
                      disabled={!canSubmit || loginWorking}
                    >
                      Entrar
                      {loginWorking && <Loading />}
                    </PrimaryButtonNew>
                  </LoginActions>
                </PasswordForm>
              );
            }}
          </Formik>
          <SignUpOption>
            <p>Ainda não possui uma conta?&nbsp;</p>
            <Link to="/cadastro">Cadastre-se</Link>
          </SignUpOption>
        </ResetPasswordBox>
      </ResetPasswordGradientContent>
    </Container>
  );
};

export default SignInNew;
