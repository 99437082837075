import styled from 'styled-components';
import InputMask from 'react-input-mask';

interface BasicInputProps {
  error?: boolean;
}

export const BasicInput = styled.input<BasicInputProps>`
  display: flex;
  border: 2px solid ;
  border-color: ${(props) =>
    props.error ? `var(--errorRed)` : `var(--gray-300)`}}
  border-radius: 0.8rem;
  height: 5.6rem;
  width: 100%;
  padding: 1.6rem;
  color: ${(props) => (props.error ? `var(--errorRed)` : `var(--gray-800)`)}}

  ::placeholder {
    color: ${(props) => (props.error ? `var(--errorRed)` : `var(--gray-800)`)}};
    font-size: 1.6rem;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type=number] {
    -moz-appearance: textfield;
  }

`;

export const MaskedInputStyled = styled(InputMask)<BasicInputProps>`
display: flex;
border: 2px solid ;
border-color: ${(props) =>
  props.error ? `var(--errorRed)` : `var(--gray-300)`}}
border-radius: 0.8rem;
height: 5.6rem;
width: 100%;
padding: 1.6rem;
color: ${(props) => (props.error ? `var(--errorRed)` : `var(--gray-800)`)}}

::placeholder {
  color: ${(props) => (props.error ? `var(--errorRed)` : `var(--gray-800)`)}};
  font-size: 1.6rem;
}

::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
[type=number] {
  -moz-appearance: textfield;
}

`;

export const PasswordInput = styled.div`
  display: flex;

  input {
    border-radius: 0.8rem 0rem 0rem 0.8rem;
    border-right: none;
  }
`;

export const RevealPasswordBox = styled.button`
  background-color: var(--greenLight);
  height: 5.6rem;
  width: 5.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 2rem;
  border-radius: 0rem 0.8rem 0.8rem 0rem;
`;
