import React from 'react';
import SignUpNew from '../../components/SignUpNew';
import Layout from '../../layout';

const Home: React.FC = () => {
  return (
    <Layout>
      <SignUpNew />
    </Layout>
  );
};

export default Home;
