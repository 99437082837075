import React, { useContext } from 'react';
// import { Container, Content } from './styles';
import ExternalNavbar from '../ExternalNavbar';
import { AccountContext } from '../Account';
import InternalNavbar from '../InternalNavbar';

const Navbar: React.FC = () => {
  const { authenticated } = useContext(AccountContext);

  return (
    <>
      <>{!authenticated ? <ExternalNavbar /> : <InternalNavbar />}</>
    </>
  );
};

export default Navbar;
