import styled from 'styled-components';

export const PrimaryButtonNew = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  height: 4.6rem;

  font-family: Montserrat;
  font-weight: normal;
  font-size: 1.4rem;

  border-radius: 2.5rem;
  color: var(--white);
  background-color: var(--greenLight);
  gap: 1rem;

  :hover {
    background-color: var(--petroleum);
  }

  &:disabled {
    background-color: var(--borderGray);
    cursor: not-allowed;
  }

  div {
    width: auto;
    height: auto;

    span {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
`;
