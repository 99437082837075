import React, { useContext, useEffect } from 'react';
import { AccountContext } from '../../components/Account';
import Layout from '../../layout';
import SignInNew from '../../components/SignInNew';

const Login: React.FC = () => {
  const { registered, setRegistered } = useContext(AccountContext);

  const disableRegister = () => {
    setRegistered(false);
  };

  useEffect(() => {
    if (registered) {
      setTimeout(disableRegister, 8000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registered]);

  return (
    <Layout>
      <SignInNew />
    </Layout>
  );
};

export default Login;
