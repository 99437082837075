import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Content, Logo, Buttons } from './styles';
import headerLogoRiachu from '../../assets/headerLogoRiachu.png';
import headerLogoMidway from '../../assets/headerLogoMidway.png';
import Button, { PrimaryButton } from '../Button';

const ExternalNavbar: React.FC = () => {
  return (
    <Container>
      <Content>
        <Logo>
          <a href="https://www.midway.com.br/" target="_blank" rel="noreferrer">
            <img className="midway" src={headerLogoMidway} alt="Midway" />
          </a>
          <a
            href="https://www.riachuelo.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="riachu" src={headerLogoRiachu} alt="Riachuelo" />
          </a>
        </Logo>
        <Buttons>
          <Link to="/cadastro">
            <PrimaryButton
              disabled={false}
              title="Cadastrar-se"
              width="20rem"
              type="button"
              ghost={false}
              black
            />
          </Link>
          <Link to="/login">
            <Button
              disabled={false}
              title="Login"
              width="20rem"
              type="button"
              ghost
              black={false}
            />
          </Link>
        </Buttons>
      </Content>
    </Container>
  );
};

export default ExternalNavbar;
