import React, { useContext } from 'react';
import { Buttons, Container, Content, Logo, Username } from './styles';
import headerLogoRiachu from '../../assets/headerLogoRiachu.png';
import headerLogoMidway from '../../assets/headerLogoMidway.png';
import { AccountContext } from '../Account';

import { PrimaryButton } from '../Button';

const InternalNavbar: React.FC = () => {
  const { name, logoutAccount } = useContext(AccountContext);

  function clickedLogoutButton(event: { preventDefault: () => void }) {
    event.preventDefault();

    logoutAccount();
  }

  return (
    <Container>
      <Content>
        <Logo>
          <a href="https://www.midway.com.br/" target="_blank" rel="noreferrer">
            <img className="midway" src={headerLogoMidway} alt="Midway" />
          </a>
          <a
            href="https://www.riachuelo.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="riachu" src={headerLogoRiachu} alt="Riachuelo" />
          </a>
        </Logo>

        <Buttons>
          <Username>{name}</Username>
          <PrimaryButton
            disabled={false}
            title="Logout"
            width="20rem"
            type="button"
            ghost={false}
            black
            onClick={(e) => clickedLogoutButton(e)}
          />
        </Buttons>
      </Content>
    </Container>
  );
};

export default InternalNavbar;
