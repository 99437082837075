import React from 'react';
import { Container } from './styles';
import { IMessage } from '../../interfaces';

const Message: React.FC<IMessage> = (props: IMessage) => {
  const { text } = props;
  return (
    <Container>
      <p>{text}</p>
    </Container>
  );
};

export default Message;
