import React from 'react';
import { ToastContainer } from 'react-toastify';
import { AccountProvider } from './components/Account';
import Routes from './routes';
import GlobalStyle from './styles/global';

const App: React.FC = () => {
  return (
    <AccountProvider>
      <Routes />
      <GlobalStyle rate={62.5} />
      <ToastContainer autoClose={5000} />
    </AccountProvider>
  );
};

export default App;
