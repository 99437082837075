import React, { useContext, useEffect } from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  RouteProps,
} from 'react-router-dom';
import NotFound from '../pages/NotFound';
import Home from '../pages/Home';
import Register from '../pages/Register';
import Login from '../pages/Login';
import Navbar from '../components/Navbar';
import ForgotPassword from '../pages/ForgotPassword';
import Panel from '../pages/Panel';
import { AccountContext } from '../components/Account';
import ScrollToTop from '../components/ScrollToTop';
import Loading from '../components/Loading';

const Routes: React.FC = () => {
  const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
    const { isAuthenticated, isAuthenticating, authenticated } =
      useContext(AccountContext);

    // const byPass = true;

    useEffect(() => {
      isAuthenticated();
    }, [isAuthenticated]);

    return (
      <Route {...rest}>
        {authenticated ? (
          children
        ) : (
          <>
            {isAuthenticating ? (
              <Loading />
            ) : (
              <Redirect to={{ pathname: '/login' }} />
            )}
          </>
        )}
      </Route>
    );
  };

  return (
    <BrowserRouter>
      <div>
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/cadastro" component={Register} />
          <Route path="/login" component={Login} />
          <Route path="/esqueci-senha" component={ForgotPassword} />
          <PrivateRoute path="/painel" component={Panel} />
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default Routes;
