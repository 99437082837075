/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { createContext, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { toast } from 'react-toastify';
import Pool from '../../utils/userPool.js';
import { IAccount } from '../../interfaces';

const AccountContext = createContext<IAccount>({} as IAccount);

const currentUser = Pool.getCurrentUser();

const AccountProvider: React.FC = ({ children }) => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);
  const [popupMessage, setPopupMessage] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [registered, setRegistered] = useState<boolean>(false);
  const [codeSended, setCodeSended] = useState<boolean>(false);

  // SEND CODE STATES
  const [sendCodeMessage, setSendCodeMessage] = useState<string>('');
  const [sendCodeWorking, setSendCodeWorking] = useState(false);
  const [sendCodeSuccess, setSendCodeSuccess] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [currentEmail, setCurrentEmail] = useState('');

  // RESET PASSWORD STATES
  const [resetPasswordWorking, setResetPasswordWorking] = useState(false);

  // LOGIN STATES
  const [loginWorking, setLoginWorking] = useState(false);

  // REGISTER STATES
  const [registerWorking, setRegisterWorking] = useState(false);

  const getName = (data: CognitoUserSession | null) => {
    const pay = data?.getIdToken().payload.name;
    setName(pay);
  };

  const isAuthenticated = async () => {
    setIsAuthenticating(true);
    return new Promise<boolean | void>((success, reject) => {
      currentUser?.getSession((err: any, session: { isValid: () => any }) => {
        if (err) {
          setAuthenticated(false);
          reject();
          return false;
        }

        if (session.isValid()) {
          setAuthenticated(true);
          getName(currentUser.getSignInUserSession());
          success();
          return true;
        }

        reject();
        setAuthenticated(false);
        return false;
      });

      setIsAuthenticating(false);
      return false;
    });
  };

  const resendAccountCode = async (Username: string) => {
    const cognitoUser = new CognitoUser({
      Username,
      Pool,
    });
    return new Promise<any>((resolve, reject) => {
      cognitoUser.resendConfirmationCode((error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
    });
  };

  const logoutAccount = () => {
    setAuthenticated(false);
    setName('');
    currentUser?.signOut();
    localStorage.clear();
  };

  const loginAccount = async (
    Username: string,
    Password: string,
    history: RouteComponentProps['history'],
  ) => {
    return new Promise<unknown>((resolve, reject) => {
      setLoginWorking(true);

      const cognitoUser = new CognitoUser({
        Username,
        Pool,
      });

      const authenticationDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (data) => {
          setLoginWorking(false);
          setHasError(false);
          setAuthenticated(true);
          history.push('/painel');
          resolve(data);
          getName(data);
          setTimeout(logoutAccount, 900000);
        },

        onFailure: (error) => {
          setHasError(true);
          if (error.name === 'UserNotFoundException') {
            setPopupMessage('Este email ainda não foi cadastrado.');
            toast.error('Este email ainda não foi cadastrado.');
          }
          if (error.name === 'NotAuthorizedException') {
            setPopupMessage('Email ou senha incorretos.');
            toast.error('Email ou senha incorretos.');
          }
          if (error.name === 'UserNotConfirmedException') {
            setPopupMessage(
              'Este email ainda não foi confirmado. Reenviando email de verificação...',
            );
            toast.error(
              'Este email ainda não foi confirmado. Reenviando email de verificação...',
            );
            resendAccountCode(Username);
          }

          setLoginWorking(false);
          reject();
        },
      });
    });
  };

  const registerAccount = async (
    name: string,
    company: string,
    terms: string,
    email: string,
    password: string,
    history: RouteComponentProps['history'],
  ) => {
    const attributeName = new CognitoUserAttribute({
      Name: 'name',
      Value: name,
    });

    const attributeCompany = new CognitoUserAttribute({
      Name: 'custom:company',
      Value: company,
    });

    const attributeTerms = new CognitoUserAttribute({
      Name: 'custom:terms',
      Value: terms,
    });

    const attributeList = [];

    attributeList.push(attributeName);
    attributeList.push(attributeCompany);
    attributeList.push(attributeTerms);
    setRegisterWorking(true);
    Pool.signUp(email, password, attributeList, [], (error) => {
      if (error) {
        setHasError(true);
        setRegistered(false);
        if (error.name === 'UsernameExistsException') {
          setPopupMessage('Este email já foi cadastrado.');
          toast.error('Este email já foi cadastrado.');
        }
        if (error.name === 'InvalidPasswordException') {
          setPopupMessage('Senha inválida!');
          toast.error('Senha inválida!');
        }
      } else {
        setHasError(false);
        setRegistered(true);
        setPopupMessage(
          'Código enviado para este email. Caso não tenha recebido, cheque sua caixa de spam!',
        );
        toast.info(
          'Código enviado para este email. Caso não tenha recebido, cheque sua caixa de spam!',
        );
        history.push('/login');
      }

      setRegisterWorking(false);
    });
  };

  const sendCodeToResetAccountPassword = async (Username: string) => {
    const cognitoUser = new CognitoUser({
      Username,
      Pool,
    });

    setSendCodeWorking(true);
    setSendCodeMessage('');
    setSendCodeSuccess(false);
    setCurrentEmail(Username);

    cognitoUser.forgotPassword({
      onSuccess: () => {
        setHasError(false);
        setCodeSended(true);
        setPopupMessage(
          'Código enviado para este email. Caso não tenha recebido, cheque sua caixa de spam!',
        );

        setSendCodeMessage(`Código enviado para o e-mail informado.
        Caso não tenha recebido, cheque sua caixa de spam.`);
        setSendCodeSuccess(true);
        setSendCodeWorking(false);
      },

      onFailure: (error) => {
        setHasError(true);
        setSendCodeSuccess(false);

        if (error.name === 'UserNotFoundException') {
          setPopupMessage('Este email ainda não foi cadastrado.');
          setSendCodeMessage('Este email ainda não foi cadastrado.');
        }
        if (error.name === 'UserNotConfirmedException') {
          setPopupMessage(
            'Este email ainda não foi confirmado. Reenviando email de verificação...',
          );
          setSendCodeMessage(
            'Este email ainda não foi confirmado. Reenviando email de verificação...',
          );
          resendAccountCode(Username);
        }

        setSendCodeWorking(false);
      },
    });
  };

  const resetAccountPassword = async (
    Username: string,
    verificationCode: string,
    newPassword: string,
    history: RouteComponentProps['history'],
  ) => {
    return new Promise<any>((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username,
        Pool,
      });

      setResetPasswordWorking(true);

      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: (data) => {
          setHasError(false);
          toast.info('Sua senha foi resetada com sucesso!');
          setPopupMessage('Sua senha foi resetada com sucesso!');
          history.push('/login');
          resolve(data);
          setResetPasswordWorking(false);
        },

        onFailure: (error) => {
          setHasError(true);
          if (error.name === 'InvalidPasswordException') {
            toast.error('Senha Inválida!');
            setPopupMessage('Senha Inválida!');
          } else if (error.name === 'InvalidParameterException') {
            toast.error('O e-mail informado é inválido!');
            setPopupMessage('Senha Inválida!');
          } else if (
            error.name === 'ExpiredCodeException' ||
            error.name === 'CodeMismatchException'
          ) {
            toast.error(
              'O código informado não é válido. Solicite um código novamente.',
            );
            setPopupMessage('Senha Inválida!');
          }

          setResetPasswordWorking(false);
          reject();
        },
      });
    });
  };

  return (
    <AccountContext.Provider
      value={{
        loginAccount,
        logoutAccount,
        resendAccountCode,
        registerAccount,
        sendCodeToResetAccountPassword,
        resetAccountPassword,
        hasError,
        setHasError,
        setPopupMessage,
        popupMessage,
        authenticated,
        currentUser,
        isAuthenticated,
        isAuthenticating,
        name,
        registered,
        setRegistered,
        codeSended,
        sendCodeMessage,
        sendCodeSuccess,
        sendCodeWorking,
        showResetPassword,
        setShowResetPassword,
        currentEmail,
        resetPasswordWorking,
        loginWorking,
        registerWorking,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export { AccountContext, AccountProvider };
