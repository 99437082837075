import styled from 'styled-components';
import { DevPortalBox, GradientContent } from '../Containers/styles';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const SendCodeGradientContent = styled(GradientContent)``;

export const ForgotPasswordBox = styled(DevPortalBox)``;

export const PasswordForm = styled.form`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 0rem;
  }

  button {
    align-self: flex-end;
    width: 30rem;
  }
`;

export const FormInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 10rem;

  p {
    color: var(--errorRed);
  }
`;

export const SuccesEmailMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 6rem;
  background-color: var(--main-100);
  margin-bottom: 3rem;
  color: var(--gray-800);
  padding: 2rem;
  gap: 2rem;
  border-radius: 0.8rem;

  svg {
    font-size: 3rem;
  }
`;

export const FailEmailMessage = styled(SuccesEmailMessage)`
  display: flex;
  background-color: #fdc4c5;
`;
