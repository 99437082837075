import axios from 'axios';

import getOafHeaders from './OafToken';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

api.interceptors.request.use(async (config) => {
  const oafHeaders = await getOafHeaders();
  const newConfig = config;

  newConfig.headers = {
    ...config.headers,
    ...oafHeaders,
  };

  // console.log(
  //   `>> ${new Date().toISOString()}     ${newConfig?.method.toUpperCase()} ${
  //     newConfig?.baseURL + newConfig?.url
  //   }`,
  // );

  return config;
});
