import React from 'react';
import { Container, Content } from './styles';
import sorryAsset from '../../assets/sorryAsset.png';
import headerLogoMidway from '../../assets/headerLogoMidway.png';

const Sorry: React.FC = () => {
  return (
    <Container>
      <Content>
        <h1>Ops... Esta página não existe!</h1>
        <img src={sorryAsset} alt="Sorry" />
        <p>
          Que tal visitar nosso portal e ficar por dentro de todas as novidades
          sobre Open Finance?
        </p>
        <a href={process.env.REACT_APP_PORTALMIDWAY}>
          Acessar Portal Open Finance {'>'}
        </a>
        <a
          href="https://openbanking.midway.com.br/"
          target="_blank"
          rel="noreferrer"
          id="midway"
        >
          <img src={headerLogoMidway} alt="Midway" />
        </a>
      </Content>
    </Container>
  );
};

export default Sorry;
