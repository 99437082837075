import React from 'react';
import { ClipLoader } from 'react-spinners';
import { Container } from './styles';

const Loading: React.FC = () => {
  return (
    <Container>
      <ClipLoader color="#22C9C3" size={60} />
    </Container>
  );
};

export default Loading;

export const SimpleLoading: React.FC = () => {
  return <ClipLoader color="#22C9C3" size={60} />;
};
