import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  height: 27rem;
  background: rgb(24, 207, 214);
  background: linear-gradient(
    90deg,
    rgba(24, 207, 214, 1) 0%,
    rgba(119, 235, 169, 1) 74%,
    rgba(179, 245, 90, 1) 100%
  );
  padding: 0 2rem;

  @media (max-width: 768px) {
    height: 30rem;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 96.4rem;
  padding: 5.5rem 0rem;
  gap: 5rem;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 5rem;
    padding-bottom: 2rem;
    gap: 1rem;
  }
`;

export const Logo = styled.div`
  color: var(--gray-800);

  h2 {
    font-size: 3.2rem;
    line-height: 4rem;
    margin-bottom: 0.5rem;
    font-weight: 900;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 2.4rem;
      line-height: 3rem;
      text-align: center;
    }
  }
`;

export const Text = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  p {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;
    color: var(--gray-800);
    width: 100%;
    max-width: 30rem;
  }

  @media (max-width: 768px) {
    /* align-items: center; */

    p {
      max-width: 100%;
      text-align: center;
    }
  }
`;

export const Image = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: -11.5rem;

  svg {
    color: var(--violetMidway);
    font-size: 2rem;
  }

  img {
    width: 38rem;
    height: 38rem;
    border-radius: 2rem;
  }

  @media (max-width: 768px) {
    margin-top: 0rem;
    order: 2;
    align-items: center;

    img {
      width: 32rem;
      height: 32rem;
    }
  }

  @media (max-width: 480px) {
    align-items: center;

    img {
      width: 70%;
      height: auto;
    }
  }
`;
