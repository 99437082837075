import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 2.5rem 2rem;
  background-color: var(--white);
  position: relative;
  z-index: -1;

  @media (max-width: 768px) {
    padding-top: 20rem;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 96.4rem;
  gap: 6rem;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 3rem;
  }

  /* @media (max-width: 360px) {
    flex-direction: column;
    padding-top: 14rem;
    text-align: center;
    align-items: center;
    gap: 2rem;
  } */
`;

export const Text = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  /* @media (max-width: 360px) {
    align-items: center;

    h1 {
      font-size: 2.4rem;
      line-height: 2.9rem;
    }

    h2 {
      font-size: 2.4rem;
      line-height: 2.9rem;
    }

    p {
      padding: 0;
    }
  } */

  h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 3.4rem;
    color: var(--petroleum);
    margin-bottom: 2rem;
    width: 22rem;

    div:nth-child(2) {
      display: flex;
      align-items: center;
      @media (max-width: 361px) {
        justify-content: center;
      }
    }

    svg {
      margin-left: 1rem;
      color: var(--greenish);
      height: 2.4rem;
      width: 2.4rem;
    }
  }

  h2 {
    font-size: 2.8rem;
    line-height: 3.4rem;
    color: var(--petroleum);
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;
    color: var(--gray-800);
    width: 90%;
    max-width: 32rem;
  }

  span {
    font-weight: 600;
    color: var(--greenLight);
  }
`;

export const Image = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  svg {
    color: var(--violetMidway);
    font-size: 2rem;
  }

  img {
    width: 26rem;
    height: 26rem;

    @media (max-width: 360px) {
      width: 17.5rem;
      height: 15.5rem;
      margin: 3.2rem 0 0 0;
    }
  }
`;
