import React from 'react';
import { Enabled, Disabled, Ghost, Black, Primary } from './styles';
import { IButton } from '../../interfaces';

const Button: React.FC<IButton> = (props: IButton) => {
  const { disabled, title, width, type, ghost, black, onClick } = props;

  const IStyle = {
    width,
  };

  return (
    <>
      {!disabled ? (
        <>
          {!ghost ? (
            <>
              {!black ? (
                <Enabled
                  title={title}
                  style={IStyle}
                  type={type}
                  onClick={onClick}
                >
                  {title}
                </Enabled>
              ) : (
                <Black
                  title={title}
                  style={IStyle}
                  type={type}
                  onClick={onClick}
                >
                  {title}
                </Black>
              )}
            </>
          ) : (
            <Ghost title={title} style={IStyle} type={type} onClick={onClick}>
              {title}
            </Ghost>
          )}
        </>
      ) : (
        <Disabled
          disabled
          title={title}
          style={IStyle}
          type={type}
          onClick={onClick}
        >
          {title}
        </Disabled>
      )}
    </>
  );
};

export default Button;

export const PrimaryButton: React.FC<IButton> = (props: IButton) => {
  const { title, width, type, onClick } = props;

  const IStyle = {
    width,
  };

  return (
    <Primary title={title} style={IStyle} type={type} onClick={onClick}>
      {title}
    </Primary>
  );
};
