import React from 'react';
import { Container, Content, Text, Image } from './styles';
import forDevelopersInfo from '../../assets/forDevelopersInfo.png';

const AboutPortal: React.FC = () => {
  return (
    <Container>
      <Content>
        <Text>
          <p>
            O objetivo deste portal é auxiliar os{' '}
            <span>desenvolvedores parceiros </span>a descobrir nossas APIs,
            aprender a usá-las, solicitar acesso e experimentá-las!
          </p>
        </Text>
        <Image>
          <img src={forDevelopersInfo} alt="For Developers Info" />
        </Image>
      </Content>
    </Container>
  );
};

export default AboutPortal;
